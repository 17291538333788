import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import {
  HomepageBanner,
  Clients,
  AboutUs,
  RecentItems,
} from "../components/home"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TransitionContext from "../context/TransitionContext"

const IndexPage = props => {
  const { location, data } = props

  const cropDefault = data.cropDefault.childImageSharp.resize
  const [scrollHero, setScrollHero] = useState(false)
  const state = useTransitionState()
  const { edges: featuredWorks } = data.featuredWorks
  const { edges: featuredBlogs } = data.featuredBlogs

  const { current } = state

  const schema = {
    "@context": `https://schema.org`,
    "@type": `Corporation`,
    name: `PAKD Media`,
    alternateName: `PAKD`,
    description: `A Brooklyn based motion and design studio developing collateral for use in creative campaigns.`,
    url: `https://pakd.media`,
    logo: `https://pakd.media/icons/icon-512x512.png?v=8d912e14b61e3134f8eba99d01d7664f`,
    contactPoint: {
      "@type": `ContactPoint`,
      telephone: `917-727-4141`,
      contactType: `sales`,
      areaServed: `US`,
      availableLanguage: `en`,
    },
    sameAs: [
      `https://www.instagram.com/pakdmedia/`,
      `https://www.youtube.com/channel/UCWdos5PN6zND13mhp9zU0Og`,
      `https://www.linkedin.com/company/pakdmedia`,
      `https://www.pakd.media`,
      `https://www.facebook.com/pakdmedia/`,
      `https://www.twitter.com/pakdmedia/`,
    ],
  }
  return (
    <>
      <SEO
        schemaMarkup={schema}
        title="Motion + Design for the Bold"
        pathname={location.pathname}
        image={cropDefault}
      />
      <TransitionContext location={location}>
        <HomepageBanner on={scrollHero} toggle={setScrollHero} data={data} />
        <RecentItems
          posts={featuredWorks}
          headerOne="Recent Work"
          headerTwo="Case Studies & Success Stories"
          subpathPrefix="works"
          buttonTitle="See All Works"
        />
        <AboutUs
          fromAnimLink={current.state.fromAnimLink}
          toggle={setScrollHero}
          on={scrollHero}
          data={data}
        />
        <RecentItems
          posts={featuredBlogs}
          headerOne="The Workshop"
          headerTwo="Recent Blog Posts"
          subpathPrefix="blog"
          buttonTitle="Visit The Workshop"
        />
        <Clients data={data} />
      </TransitionContext>
    </>
  )
}
export const pageQuery = graphql`
  query HomeIndexQuery {
    bg: file(relativeDirectory: { regex: "/home/" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    cropDefault: file(absolutePath: { regex: "/profiles/PAKDSocial/" }) {
      childImageSharp {
        resize(width: 1024, height: 1024, cropFocus: CENTER) {
          src
          width
          height
        }
      }
    }
    clients: allFile(
      filter: { relativeDirectory: { regex: "/clients/" } }
      sort: { order: DESC, fields: id }
      limit: 18
    ) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 75) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
      }
    }
    covervideo: file(absolutePath: { regex: "/ReelFallback/" }) {
      id
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    featuredBlogs: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, draft: { eq: false } }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            templateKey
            bgcolor
            date(formatString: "MM.DD.YY")
            featuredpost
            homehero: featuredimage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    featuredWorks: allMdx(
      filter: { frontmatter: { type: { eq: "works" }, draft: { eq: false } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            date(formatString: "MM.DD.YY")
            clientName
            projectTitle
            itemColor
            slug
            homehero {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({
      isCaseStudy: PropTypes.bool,
      isWorkshopBlog: PropTypes.bool,
    }),
  ]),
}
