import React, { useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-grid-system"
import { rhythm } from "../../../config/typography"
import { Button, Header1, NavColorWaypoint } from "../../elements"
import ProjectItem from "../works/ProjectItem"
import { NavContext } from "../../context/NavContext"

const RecentItems = ({
  posts,
  headerOne,
  headerTwo,
  subpathPrefix,
  buttonTitle,
}) => {
  const { yPos, setNavigationColor } = useContext(NavContext)
  const blogEntranceRef = useRef()
  const blogContainerRef = useRef()

  useEffect(() => {
    if (blogEntranceRef.current) {
      const container = blogContainerRef.current.getBoundingClientRect()
      const containerHeight = container.height
      const entrance = blogEntranceRef.current.getBoundingClientRect()
      if (entrance.top < 0) {
        setNavigationColor(`#2c3e50`)
      }
      if (entrance.top <= containerHeight * -1) {
        setNavigationColor(`#ffffff`)
      }
    }
  }, [yPos])

  return (
    <Container
      fluid
      className=" padbtm padtop innerwrap"
      style={{
        backgroundColor: `#fdfdfd`,
      }}
    >
      <div ref={blogContainerRef}>
        <NavColorWaypoint ref={blogEntranceRef} />
        <Row align="center" justify="center" marginbottom={0}>
          <Col lg={12}>
            <Header1 lineOne={headerOne} lineTwo={headerTwo} />
          </Col>
        </Row>
        <Row align="center" justify="center" gutterWidth={16}>
          {posts.map((post, index) => {
            let navPath
            if (subpathPrefix === `works`) {
              navPath = subpathPrefix + post.node.frontmatter.slug
            } else if (subpathPrefix === `blog`) {
              navPath = subpathPrefix + post.node.fields.slug
            }
            return (
              <Col md={4} sm={6} key={post.node.fields.slug}>
                <ProjectItem
                  post={post}
                  index={index}
                  aria-label={` Visit post ${post.node.title} from PAKD Media`}
                  subpath={navPath}
                />
              </Col>
            )
          })}
        </Row>
        <Row
          align="center"
          justify="center"
          style={{
            marginTop: `${rhythm(1.5)}`,
            marginBottom: `${rhythm(1.5 / 2)}`,
          }}
        >
          <Col>
            <Button secondary linkTo={`/${subpathPrefix}`}>
              {buttonTitle}
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

RecentItems.propTypes = {
  data: PropTypes.shape({
    featuredBlogs: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default RecentItems
