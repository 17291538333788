import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Container, Row } from "react-grid-system"
import { below } from "../../../config/utilities"
import { rhythm } from "../../../config/typography"
import { Header1 } from "../../elements"

const Clients = ({ data }) => {
  const items = data.clients.edges

  return (
    <Container fluid className=" padbtm padtop innerwrapmed">
      <Header1 lineOne="Clients" lineTwo="Channeling some unicorn magic." />

      <Row align="center" justify="center">
        {items.map(item => (
          <Anim key={item.node.id}>
            <Img
              fixed={item.node.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="50% 50%"
              title={item.node.childImageSharp.fixed.originalName}
              alt={item.node.childImageSharp.fixed.originalName}
            />
          </Anim>
        ))}
      </Row>
    </Container>
  )
}

const Anim = styled.div`
  max-width: 100px;
  padding: ${rhythm(1 / 2)};
  ${below.xs`
  padding:${rhythm(1 / 4)};`}
`
export default Clients
