import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import { animated } from "react-spring"
import { rhythm } from "../../../config/typography"
import { shade } from "polished"
import AnimationLink from "../nav/AnimationLink"
import { motion } from "framer-motion"

const containerVariants = {
  unhovered: {},
  hovered: {},
}
const imgVariants = {
  unhovered: {
    scale: 1.05,
    opacity: 1,
  },
  hovered: {
    scale: 1,
    opacity: 0.15,
  },
}

const textHoverVariants = {
  unhovered: {
    opacity: 0,
    x: -25,
    transition: {
      stiffness: 5,
      damping: 2,
    },
  },
  hovered: {
    opacity: 1,
    x: 0,
    transition: {
      stiffness: 5,
      damping: 2,
    },
  },
}
const ProjectImage = ({ post, subpath, mainImg }) => {
  // useState for Hover events

  // BG Color extrapolation from blog / works
  let bgColor
  {
    if (post.node.frontmatter.itemColor) {
      bgColor = post.node.frontmatter.itemColor.toString()
    } else if (post.node.frontmatter.bgcolor) {
      bgColor = post.node.frontmatter.bgcolor.toString()
    }
  }
  // Shade BG Color
  const bgColShade = shade(0.3, bgColor)

  // set featured images alt attr
  let imageAlt
  if (post.node.frontmatter.clientName !== undefined) {
    imageAlt = `Featured image from the '${post.node.frontmatter.clientName} - ${post.node.frontmatter.projectTitle}' PAKD Media project.`
  } else {
    imageAlt = `Featured image from '${post.node.frontmatter.title}' in the PAKD Media Workshop.`
  }

  return (
    <CardContainer
      whileHover="hovered"
      initial={false}
      animate="unhovered"
      bgColShade={bgColShade}
    >
      <AnimationLink to={subpath}>
        <ImageContainer variants={containerVariants} bgColShade={bgColShade}>
          <motion.h6 bgColShade={bgColShade} variants={textHoverVariants}>
            View More
          </motion.h6>
          <motion.div
            variants={imgVariants}
            style={{ width: `100%`, height: `100%` }}
          >
            <Img
              fluid={mainImg}
              className="imagewraps"
              objectFit="cover"
              objectPosition="50% 50%"
              alt={imageAlt}
            />
          </motion.div>
        </ImageContainer>
      </AnimationLink>

      <TextWrap>
        <motion.h3>
          {post.node.frontmatter.clientName || post.node.frontmatter.title}
        </motion.h3>
        <motion.h6 style={{ marginBottom: 0 }}>
          {post.node.frontmatter.projectTitle ||
            `#` +
              post.node.frontmatter.tags[0] +
              ` ` +
              ` ` +
              `#` +
              post.node.frontmatter.tags[1] +
              ` ` +
              ` ` +
              `#` +
              post.node.frontmatter.tags[2]}
        </motion.h6>
      </TextWrap>
    </CardContainer>
  )
}

export default ProjectImage

export const CardContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "image"
    "title";
  grid-template-rows: 3fr 1fr;
`

export const ImageContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: "image";
  width: 100%;
  height: 100%;
  overflow: hidden;
  h6 {
    font-size: ${rhythm(1)};
    position: absolute;
    z-index: 10;
    color: ${props => props.theme.brand.primaryalt};
    margin: 0;
    border-bottom: ${rhythm(1 / 12)} solid ${props => props.bgColShade};
  }
  .imagewraps {
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .bg-col-shade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    height: 100%;
    * {
      text-align: center;
      color: white;
      line-height: ${rhythm(1 / 2)};
    }
  }
`
export const ImageContent = styled(motion.div)`
  width: 100%;
  height: 100%;
`

export const ImageWrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  margin: 0 auto;

  * {
    width: 100%;
    height: 100%;
  }
  .imagewraps {
    height: 100%;
  }
`

export const TextWrap = styled(animated.div)`
  z-index: 100;
  height: 100%;
  padding: 1rem 0;
  flex-direction: column;
  white-space: ${props => props.whitespace || `nowrap`};
  h3 {
    text-align: left;
    margin: auto 0;
    font-size: ${rhythm(0.8)};
    margin-bottom: ${rhythm(0.33)};
  }
  h6 {
    font-size: ${rhythm(0.6)};
    color: ${props => props.theme.colors.grey};
    padding-top: 0.5em;
    text-align: right;
    padding-right: 0.25rem;
    border-top: 1px dotted ${props => props.theme.brand.primaryfade2};
  }
`
export const ClientName = styled.h3`
  color: ${props => props.theme.brand.primaryalt};
`
export const ProjectTitle = styled.h6`
  color: ${props => props.theme.brand.primaryfade3};

  text-transform: uppercase;
  outline: none;
  border: none;
`

ProjectImage.propTypes = {
  post: PropTypes.shape({
    node: PropTypes.shape({
      frontmatter: PropTypes.shape({
        projectTitle: PropTypes.string,
        clientName: PropTypes.string,
        homehero: PropTypes.object,
      }),
    }),
  }).isRequired,
  style: PropTypes.object,
}
