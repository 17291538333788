import React from "react"
import LottieInView from "./LottieInView"
import Anim from "./scroll.json"
import styled from "styled-components"
import { rhythm } from "../../../config/typography"
const ScrollCallout = () => (
  <CalloutWrapper>
    <LottieInView
      animationData={Anim}
      id="Scroll Callout Lottie"
      speed={1}
      width="72px"
      height="72px"
      threshold={1}
      inViewTimeout={200}
      loop={true}
    />
    <h6>scroll</h6>
    <LineDiv />
  </CalloutWrapper>
)

const CalloutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  z-index: 100;
  width: ${rhythm(4.5)};
  height: ${rhythm(15)};
  left: 50%;
  margin-left: -${rhythm(4.5 / 2)};
  .lottiewrapper {
    margin-bottom: ${rhythm(2)};
  }
  h6 {
    margin: 0;
    color: white;
  }
`
const LineDiv = styled.div`
  width: 1px;
  height: ${rhythm(5)};
  background: ${props => props.theme.brand.orangered};
`
export default ScrollCallout
