import React from "react"
import styled from "styled-components"
import CoverVideo from "./CoverVideo"
import ReelModal from "../Modal/ReelModal"
import { ButtonInt } from "../../elements"
import { rhythm } from "../../../config/typography"
import { Row, Col } from "react-grid-system"

const Homepage = ({ on, data }) => (
  <CoverVideo on={on} data={data.covervideo}>
    <ExtraLargeText>
      <HomepagePara />
      <ReelModal>
        <ButtonInt secondary>Watch The Reel</ButtonInt>
      </ReelModal>
    </ExtraLargeText>
  </CoverVideo>
)

export default Homepage

const HomepagePara = () => (
  <>
    <Row align="center" justify="center">
      <h1 className="lgspan">
        <Col>
          <span className="outlinedtxt3">Motion + Design</span>
        </Col>
        <Col>
          <span>for the bold</span>
        </Col>
      </h1>
    </Row>
    <Row>
      <Col>
        <p>Uniquely creative, tenaciously compelling.</p>
      </Col>
    </Row>
  </>
)
const ExtraLargeText = styled.div`
  padding: ${rhythm(0.25)};
  transform: translate3d(0, 24px, 0);
  .lgspan {
    font-family: Teko;
    text-transform: uppercase;
    line-height: ${rhythm(1.66)};
    margin: 0;
  }
  p {
    font-weight: 500;
    color: white;
    margin: auto;
  }
`
