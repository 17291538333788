/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Container, Row, Col, Visible } from "react-grid-system"
import { Header1, Button } from "../../elements"
import { rhythm } from "../../../config/typography"
import BGImageBlog from "../../elements/BGImageBlog"

const AboutUs = ({ data }) => (
  <BGImageBlog imageData={data.bg.childImageSharp.fluid} bgColor="#11181f">
    <Container
      fluid
      css={`
        position: relative;
        div[title="homeunicorn"] {
          width: 32px !important;
          height: 32px !important;
          * {
            stroke: white;
            opacity: 1;
          }
        }
      `}
      className=" padbtm padtop "
    >
      <Header1
        secondary
        lineOne="About"
        lineTwo="Uniquely creative. Tenaciously compelling."
      />

      <Row align="center" justify="center">
        <Col
          style={{
            zIndex: 10,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-around`,
          }}
        >
          <PBlock>
            PAKD Media is a collective of multi-disciplined creatives passionate
            about the potential branding and design has to transform the way we
            interface with the world. Our goal is to help our clients drive
            their brand maturity and develop a design system that aligns with
            their goals, mission and values.
          </PBlock>
          <PBlock>
            We push our clients to think outside the box. If you have an already
            tenacious brand presence, we'll work to make it stronger. If you've
            developed a bold direction for your new brand or product, we'll
            double down and make it bolder. If you have an already performant
            brand strategy, we'll continue to build upon that foundation,
            translating new ideas into undiscovered value.
          </PBlock>
          <PBlock>
            PAKD Media's leadership team brings over 50 years of combined
            experience collaborating with startups, emerging brands, and
            innovative market leaders to elevate their brand maturity. Every
            project at PAKD is backed by a cohesive process curated and refined
            through years of invaluable experience in the marketplace.
          </PBlock>
          <Row
            align="center"
            justify="center"
            style={{
              marginBottom: `${rhythm(1.5 / 2)}`,
            }}
          >
            <Button secondary linkTo="/services">
              Our Services
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  </BGImageBlog>
)

const PBlock = ({ children }) => (
  <>
    <Visible xs>
      <Row justify="center">
        <Col xs={12}>
          <p className="whitetext">{children}</p>
        </Col>
      </Row>
    </Visible>
    <Visible sm md lg xl>
      <p className="whitetext p50">{children}</p>
    </Visible>
  </>
)
export default AboutUs
