import React from "react"
import PropTypes from "prop-types"
import ProjectCard from "./ProjectCard"

const ProjectItem = ({ subpath, post, index, title }) => {
  const mainImg = post.node.frontmatter.homehero.childImageSharp.fluid
  const tracedImg =
    post.node.frontmatter.homehero.childImageSharp.fluid.tracedSVG
  const col = post.node.frontmatter.itemColor
  return (
    <>
      <ProjectCard
        index={index}
        post={post}
        subpath={subpath}
        id={post.node.fields.slug}
        arialabel={
          post.node.frontmatter.projectTitle || post.node.frontmatter.title
        }
        state={{ isWorksPage: true }}
        mainImg={mainImg}
        tracedImg={tracedImg}
        title={title}
        col={col}
      />
    </>
  )
}

export default ProjectItem

ProjectItem.propTypes = {
  post: PropTypes.shape({
    node: PropTypes.shape({
      frontmatter: PropTypes.shape({
        projectTitle: PropTypes.string,
        clientName: PropTypes.string,
        homehero: PropTypes.object.isRequired,
        title: PropTypes.string,
        itemColor: PropTypes.string,
      }),
    }),
  }).isRequired,
  height: PropTypes.object,
  transform: PropTypes.object,
}
