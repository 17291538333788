import React, { useState, useEffect, useContext } from "react"
import { useTransition } from "react-spring"
// eslint-disable-next-line
import styled from "styled-components"
import { NavContext } from "../../context/NavContext"
import Modal from "./Modal"
import { TransitionPortal } from "gatsby-plugin-transition-link"

const ReelModal = ({ children }) => {
  const [on, toggle] = useState(false)

  const { setModalOpen } = useContext(NavContext)

  useEffect(() => {
    if (!on) {
      setModalOpen(false)
    } else {
      setModalOpen(true)
    }
  }, [on])

  return (
    <div onClick={() => toggle(!on)}>
      {children}

      <TransitionPortal level="top">
        <Modal
          on={on}
          closeModal={() => toggle(false)}
          useCloseButton
          useModalCard
          bgColor="rgba(17, 24, 31,.9)"
        >
          <VimeoContainer>
            <ReelPlayer
              src="https://player.vimeo.com/video/323642003?title=1&byline=1&portrait=0&autoplay=1&loop=0&autopause=0"
              allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </VimeoContainer>
        </Modal>
      </TransitionPortal>
    </div>
  )
}

const VimeoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 41.67%;
  height: auto;
  margin: 0 auto;
  width: 90vw;
`

const ReelPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`
export default ReelModal
