import React, { useRef, useEffect, useContext } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import ReelMP4 from "../../media/PAKDReel.mp4"
import ReelWebM from "../../media/PAKDReel.webm"
import PropTypes from "prop-types"
import { NavContext } from "../../context/NavContext"
import { isBrowser } from "react-device-detect"
import Img from "gatsby-image"
import { motion, useAnimation } from "framer-motion"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"

import ScrollCallout from "../lottie/ScrollCallout"

const CoverVideo = ({ children, data }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: false,
  })
  const videoEl = useRef()
  const { isModalOpen, isNavOpen } = useContext(NavContext)
  const state = useTransitionState()
  const controls = useAnimation()

  useEffect(() => {
    if (isBrowser) {
      if (videoEl.current) {
        videoEl.current.onloadeddata = () => {
          controls.start({
            scale: 1,
            opacity: 1,
            transition: { duration: 1 },
          })
        }
        const playPromise = videoEl.current.play()
        if (state.transitionStatus === `entered`) {
          if (inView) {
            videoEl.current.play()
          } else if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch(() => {
                // Auto-play was prevented
                // Show paused UI.
              })
          } else if (state.transitionStatus === `exiting`) {
            videoEl.current.pause()
          }
        }
      }
    }
  }, [state])

  useEffect(() => {
    if (isBrowser) {
      if (isModalOpen) {
        videoEl.current.pause()
      }
      if (isNavOpen) {
        videoEl.current.pause()
      } else {
        videoEl.current.play()
      }
    }
  }, [isModalOpen, isNavOpen])

  return (
    <Wrapper>
      <HeroWrap>
        <TextWrap>{children}</TextWrap>

        <ScrollCallout width={75} height={75} />

        {isBrowser ? (
          <motion.video
            autoPlay
            initial={{ opacity: 0, scale: 1.14 }}
            animate={controls}
            muted
            playsInline
            loop
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            title="PAKD Media Reel"
            aria-label="PAKD Media Reel"
            crossOrigin="anonymous"
            ref={videoEl}
            preload="auto"
          >
            <source src={ReelWebM} type="video/webm" />
            <source src={ReelMP4} type="video/mp4" />
            {` `}
          </motion.video>
        ) : (
          <Img className="fallbackimage" fluid={data.childImageSharp.fluid} />
        )}

        <BannerColor ref={ref} />
      </HeroWrap>
    </Wrapper>
  )
}

CoverVideo.propTypes = {
  children: PropTypes.object,
}

export default CoverVideo

const Wrapper = styled(motion.div)`
  display: flex;

  flex-direction: column;
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0 !important;
  min-height: 200px;
  z-index: 400000;
`

const HeroWrap = styled.div`
  display: grid;
  height: 100%;
  grid-template: 96px 1fr 96px / auto;
  position: relative;

  video,
  .fallbackimage {
    grid-area: 1 / 1 / -1 / -1;
    z-index: 10;
    opacity: 1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    width: 100%;
  }
`
const TextWrap = styled.div`
  grid-area: 1 / 1 / -1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  width: 100%;
  flex-direction: column;
  z-index: 300000;
`
const BannerColor = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background: ${props => props.theme.fades.primaryaltfade};
`
